// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-company-template-js": () => import("./../src/templates/company-template.js" /* webpackChunkName: "component---src-templates-company-template-js" */),
  "component---src-templates-legal-template-js": () => import("./../src/templates/legal-template.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-templates-our-community-js": () => import("./../src/templates/our-community.js" /* webpackChunkName: "component---src-templates-our-community-js" */),
  "component---src-templates-team-member-js": () => import("./../src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */)
}

