import { createStore as reduxCreateStore } from "redux"
import { navigate } from "gatsby"

const typeOptions = [
  { value: "people", label: "Our Community" },
  { value: "companies", label: "Our Companies" },
  { value: "all", label: "All" },
]

const defaultCompanyFilterValue = { value: null, label: "All" }
const defaultPeopleFilterValue = { value: null, label: "Everyone" }

function generateURL(type, filter, sorting) {
  if (filter)
    return `/our-community?type=${type}&sorting=${sorting}&filter=${filter}`
  else return `/our-community?type=${type}&sorting=${sorting}`
}

function findLabel(value, options) {
  let label = null
  options.forEach(option => {
    if (option.value === value) {
      label = option.label
    }
  })
  return label
}

const reducer = (state, action) => {
  // switch(action.type) {
  //   case: "SET_TYPE_VALUE":
  let newURL

  switch (action.type) {
    case "SET_TYPE_VALUE":
      newURL = generateURL(action.value, null, state.sorting)
      //console.log("NAVIGATE TYPE: " + newURL)
      navigate(newURL, { replace: true })

      let filterSelect = {
        value: defaultPeopleFilterValue,
        options: state.peopleFilterOptions,
      }
      if (action.value === "all" || action.value === "companies") {
        filterSelect = {
          value: defaultCompanyFilterValue,
          options: state.companyFilterOptions,
        }
      }

      return Object.assign({}, state, {
        typeSelect: {
          ...state.typeSelect,
          value: {
            value: action.value,
            label: findLabel(action.value, state.typeSelect.options),
          },
        },
        filterSelect: filterSelect,
      })

    case "SET_FILTER_VALUE":
      newURL = generateURL(
        state.typeSelect.value.value,
        action.value,
        state.sorting
      )
      //console.log("NAVIGATE FILTER: " + newURL)
      navigate(newURL, { replace: true })
      return Object.assign({}, state, {
        filterSelect: {
          ...state.filterSelect,
          value: {
            value: action.value,
            label: findLabel(action.value, state.filterSelect.options),
          },
        },
      })

    case "SET_COMPANY_FILTER_OPTIONS":
      if (state.typeSelect.value.value === "companies") {
        return Object.assign({}, state, {
          companyFilterOptions: action.options,
          filterSelect: { ...state.filterSelect, options: action.options },
        })
      } else {
        return Object.assign({}, state, {
          companyFilterOptions: action.options,
        })
      }

    case "SET_PEOPLE_FILTER_OPTIONS":
      if (state.typeSelect.value.value === "people") {
        return Object.assign({}, state, {
          peopleFilterOptions: action.options,
          filterSelect: { ...state.filterSelect, options: action.options },
        })
      } else {
        return Object.assign({}, state, { peopleFilterOptions: action.options })
      }

    case "TOGGLE_SORTING":
      const newSorting = state.sorting === "recent" ? "alphabetical" : "recent"
      newURL = generateURL(
        state.typeSelect.value.value,
        state.filterSelect.value.value,
        newSorting
      )
      //console.log("NAVIGATE SORTING: " + newURL)
      navigate(newURL, { replace: true })
      return Object.assign({}, state, {
        sorting: newSorting,
      })

    default:
      return state
  }
}

const initialState = {
  typeSelect: {
    value: { value: "people", label: "Our Community" },
    options: typeOptions,
  },
  filterSelect: { value: defaultPeopleFilterValue, options: null },
  defaultCompanyFilterValue,
  defaultPeopleFilterValue,
  companyFilterOptions: [defaultCompanyFilterValue],
  peopleFilterOptions: [defaultPeopleFilterValue],
  sorting: "recent",
}

const createStore = () => reduxCreateStore(reducer, initialState)

export default createStore
